<template>
  <router-view/>
</template>
<script>
  export default {
    name: 'App',
    data() {
      return {
      }
    },
    watch: {
      '$route'()
      {
        document.title="Blog - Kompre.pl"
      }
    },
    components: {
    },
    methods: {
    },
  }
</script>
<style lang="scss">
</style>
